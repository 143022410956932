import React from 'react';

const FormInput = (props) => {
  return (
    <>
      <label htmlFor={props.id}>{props.caption}</label>
      <input type="text" id={props.id} name={props.id}
        style={props.style}
        placeholder={props.placeholder} onChange={props.onChange} />
    </>
  )
}
export default FormInput
