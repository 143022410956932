import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Image from "../components/imageFromStarterPleaseStartHere"
import Contact from "../components/contact"

export default () => (
  <Layout>
    <SEO title="Contact" />
    <Image/>
    <Contact/>
  </Layout>
)
