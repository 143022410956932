import React, { useState } from "react"
import { navigate } from 'gatsby'
import containerStyles from "./contact.module.css"
import FormInput from "../components/formInput"
import axios from "axios"

const Contact = () => {

    
  const defaultName = "Your name....."
  const defaultEmail = "Your email....."
  const defaultOrganisation = "Your organisation....."
  const defaultMessage = "Your message....."
  const defaultPriority = "Your Priority....."
  const [name, setName] = useState(defaultName)
  const [email, setEmail] = useState(defaultEmail)

  const [organisation, setOrganisation] = useState(defaultOrganisation)
  const [message, setMessage] = useState(defaultMessage)
  const [priority, setPriority] = useState(defaultPriority)
  const [performValidation, setPerformValidation] = useState(false)
  //const [valid, setValid] = useState(false)

const [apiMessage, setApiMessage] = useState("")
async function callAPI() {
  //var apiServerUrl="http://localhost:7071"
  var apiServerUrl="https://goresfnechohello20191023100306.azurewebsites.net"
    //let url = `${apiServerUrl}/api/EchoHello?name=${name}`
    let url = `${apiServerUrl}/api/SendGrid`
    let config={
        name:`${name}`,
        priority:`${priority}`,
        organisation:`${organisation}`,
        email:`${email}`,
        message:`${message}`,

    }
    axios
      .post(url,config)
      .then(function(response) {
        // handle success
        // console.log("response async")
        // console.log(response)
        setApiMessage("Message Sent")
        //console.log(apiMessage)
        alert("Your message has been sent. Thank you.")
        navigate('/')
        //resetForm()
      })
      .catch(function(error) {
        // handle error
        setApiMessage("There was an error; please try later")
      })
      // .finally(function() {
      //   // always executed
      //   console.log("finally async")
      // })
  }
  
  function resetForm(){
    setName(defaultName)
    setEmail(defaultEmail)

    setMessage(defaultMessage)
    setOrganisation(defaultOrganisation)
    setPriority(defaultPriority)
    console.log(message)
  }

  function getStyleForMandatoryInput(value, defaultValue) {
    if (performValidation) {
      if (value === defaultValue) {
        return {
          backgroundColor: "#ff8080",
        }
      }
      return {}
    }
    return {}
  }
  return (
    <>
      <h3>Contact Us</h3>

      <div className="container">
        {/* <form method="post" action="#"> */}

        <FormInput
          caption="Name *"
          id="name"
          style={getStyleForMandatoryInput(name, defaultName)}
          placeholder={defaultName}
          onChange={event => setName(event.target.value)}
        />

        <FormInput
          caption="Email *"
          id="email"
          style={getStyleForMandatoryInput(email, defaultEmail)}
          placeholder={defaultEmail}
          onChange={event => setEmail(event.target.value)}
        />

        <FormInput
          caption="Organisation"
          id="organisation"
          placeholder={defaultOrganisation}
          onChange={event => setOrganisation(event.target.value)}
        />

        <input
          type="hidden"
          id="priority"
          name="priority"
          placeholder={defaultPriority}
          onChange={event => setPriority(event.target.value)}
        />

        <label htmlFor="message">Message *</label>
        <textarea
          id="message"
          name="message"
          //style={{ height: "200px" }}
          style={getStyleForMandatoryInput(message, defaultMessage)}
          onChange={event => setMessage(event.target.value)}
          placeholder={defaultMessage}
        ></textarea>

        {/* <input type="submit" value="Submit" /> */}
        {/* </form> */}

        <button
          onClick={event => {
            //setValid(true)
            setPerformValidation(true)

            if (
              message !== defaultMessage &&
              name !== defaultName &&
              email !== defaultEmail
            ) {
              if (priority === defaultPriority) {
                callAPI()
                return
              }
              alert("BOT")
            }
          }}
        >
          Submit
        </button>
        <p>{apiMessage}</p>
      </div>
    </>
  )
}

export default Contact
